<template>
	<v-layout row wrap align-center justify-left>
		<v-tooltip v-if="!conditionalDisplay.isMobileApp" bottom>
			<template v-slot:activator="{ on }">
				<v-btn v-if="$vuetify.breakpoint.smAndDown" icon @click="showCompanyList()" v-on="on">
					<v-icon color="primary">format_list_bulleted</v-icon>
				</v-btn>
			</template>
			<span v-t="'documents.show_companies'" />
		</v-tooltip>
		<v-tooltip bottom>
			<template v-slot:activator="{ on }">
				<v-btn v-if="$vuetify.breakpoint.smAndDown && !isEcmRoot" icon @click="showMobileDetails()" v-on="on">
					<v-icon v-if="conditionalDisplay.isMobileApp" color="primary" class="mr-1">info</v-icon>
					<v-icon v-else color="primary" class="mr-1">call_to_action</v-icon>
				</v-btn>
			</template>
			<span v-t="'documents.show_actions'" />
		</v-tooltip>
		<DisplayModeButton />
		<ShowSelectionDetails v-if="!isEcmRoot" v-model="showSelectionDetails" />
		<DocumentsToolbarSorting/>
		<CounterButton v-model="model" single-button-display />
		<AddFolderButton v-if="!conditionalDisplay.isMobileApp" v-model="currentFolder" simple-button-display :selected-elements="selectedElements" />
		<AddDocumentButton v-if="!conditionalDisplay.isMobileApp" simple-button-display :parent="currentFolder" :selected-elements="selectedElements" />
		<RotateDocumentButtons v-if="!conditionalDisplay.isMobileApp" v-model="model" simple-button-display />
		<DownloadButton v-model="model" />
		<SignatureButtons v-if="!conditionalDisplay.isMobileApp" v-model="model" single-button-display />
		<ShareBtn v-model="model" />
		<ValidationButtons v-if="!conditionalDisplay.isMobileApp" v-model="model" single-button-display />
		<NodeSurveyButtons v-if="!conditionalDisplay.isMobileApp" v-model="model" single-button-display />
		<DeleteButton v-model="model" :selected-folder="currentFolder" simple-button-display />
		<RestoreFromTrashButton v-model="model" simple-button-display :parent="currentFolder" />
		<EmptyTrashButton v-if="routeIsTrash" v-model="trashedElements" simple-button-display />
	</v-layout>
</template>

<script>
import DocumentsManagerModuleGuardVue from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'
import EmptyTrashMixin from "@/mixins/Trash/TrashMixin";

export default {
	name: 'DocumentsToolbarActions',
	components: {
		AddDocumentButton: () => ({
			component: import('@/components/Documents/Actions/Add/AddDocumentButton')
		}),
		AddFolderButton: () => ({
			component: import('@/components/Documents/Actions/Add/AddFolderButton')
		}),
		DeleteButton: () => ({
			component: import('@/components/Documents/Actions/Delete/DeleteButton')
		}),
		DisplayModeButton: () => ({
			component: import('@/components/Documents/Actions/DisplayMode/DisplayModeButton')
		}),
		DownloadButton: () => ({
			component: import('@/components/Documents/Actions/Download/DownloadButton')
		}),
		RestoreFromTrashButton: () => ({
			component: import('@/components/Documents/Actions/Trash/RestoreFromTrashButton')
		}),
		RotateDocumentButtons: () => ({
			component: import('@/components/Documents/Actions/Rotate/RotateDocumentButtons')
		}),
		ValidationButtons: () => ({
			component: import('@/components/Documents/Actions/Validation/ValidationButtons')
		}),
		NodeSurveyButtons: () => ({
			component: import('@/components/Documents/Actions/Survey/NodeSurveyButtons')
		}),
		ShareBtn: () => ({
			component: import('@/components/Documents/Share/ShareBtn')
		}),
		SignatureButtons: () => ({
			component: import('@/components/Documents/Signature/SignatureButtons')
		}),
		CounterButton: () => ({
			component: import('@/components/Documents/Counter/CounterButton')
		}),
		ShowSelectionDetails: () => ({
			component: import('@/components/Documents/Actions/Details/ShowSelectionDetails')
		}),
		DocumentsToolbarSorting: () => ({
			component: import('@/components/Documents/Toolbar/DocumentsToolbarSorting')
		}),
	},
	mixins: [
		DocumentsManagerModuleGuardVue,
		EmptyTrashMixin,
	],
	props: {
		selectedFolder: {
			type: Object,
			required: false,
			default: null
		},
		showDetails: {
			type: Boolean,
			required: false,
			default: true
		},
		value: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	computed: {
		model: {
			get: function () {
				let result
				if (this.value.length > 0) {
					result = this.value
				} else if (this.currentFolder) {
					result = [this.currentFolder]
				} else {
					result = []
				}
				return result
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		currentFolder: {
			get: function () {
				return this.selectedFolder
			},
			set: function (value) {
				if (value) {
					this.$emit('selection-changed', value)
				}
			}
		},
		selectedElements: function () {
			return this.model?.filter(node => node && node.id != this.currentFolder?.id)
		},
		showSelectionDetails: {
			get: function () {
				return this.showDetails
			},
			set: function (value) {
				this.$emit('do-show-details', value)
			}
		},
		canShowFolderAction: function () {
			return this.model.length == 0 || this.model.every(node => node.is_folder)
		},
		isEcmRoot: function () {
			return this.currentFolder && this.currentFolder.is_root
		}
	},
	methods: {
		showCompanyList: function () {
			this.appEventBus.emit(this.appEvents.SHOW_SIDEBAR)
		},
		showMobileDetails: function () {
			this.eventBus.emit(this.events.SHOW_MOBILE_DETAILS)
		}
	}
}
</script>
